.app-wrapper {
  box-sizing: border-box;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .app-header,
  .app-footer {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .page-body {
    flex-grow: 1;
  }
}
