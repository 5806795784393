.home-container {
  .report-header {
    margin-bottom: 4rem;
    display: flex;

    h1 {
      flex-grow: 1;
    }
  }

  .report-panel {
    // display: flex;
    justify-content: space-between;
    .summary {
      flex-grow: 1;
      padding-right: 1rem;

      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        column-gap: 1rem;
        row-gap: 1rem;
      }
    }

    .another-view {
      flex-grow: 1;
      // border-left: 1px solid white;
      // padding-left: 1rem;
    }
  }
}
